<template>
    <div class="article-panel taskdata-panel">
        <div class="taskdata-panel-inner">
            <h3 class="g-title">调解详情</h3>
            <div class="article-bd">
                <div class="data-list-wraper mt-20">
                    <div
                        class="td-item-box step1-box step4-box"
                        style="padding:10px;"
                    >
                        <div class="br-bd">
                            <el-timeline>
                                <el-timeline-item placement="top">
                                    <div class="card-bd">
                                        <el-card>
                                            <table class="el-table zyzj_table">
                                                <thead>
                                                    <tr>
                                                        <th
                                                            lay-data="{align:'center'}"
                                                            colspan="2"
                                                            style="font-size:16px;height:40px;"
                                                        >争议资金明细</th>
                                                    </tr>
                                                    <tr>
                                                        <th style="width:50%;">雇主托管争议资金</th>
                                                        <th style="width:50%;">极客托管争议履约保证金</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <span
                                                                v-for="(block,index) in task.trade.block_employer"
                                                                :key="index"
                                                                v-if="block.is_appeal == 1"
                                                            >{{parseInt(block.remuneration)}}</span>
                                                        </td>
                                                        <td>{{parseInt(task.trade.bond)}} 元</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </el-card>
                                    </div>
                                </el-timeline-item>

                                <!-- 分阶段支付已支付状态 通过在 el-timeline-item上添加class="active" 来区分开始状态，通过设置button按钮的type来控制button颜色，是否可点用:disabled="true"或:disabled="false"控制-->
                                <el-timeline-item
                                    class="active"
                                    :timestamp="item.create_time"
                                    placement="top"
                                    v-for="(item,index) in task.appeal"
                                    :key="index"
                                >
                                    <el-card>
                                        <div class="card-bd">
                                            <div class="cardbd-l">
                                                <div style="font-size:16px;font-weight:normal;width:100%;height:30px;float:none;clear:both;">
                                                    <img
                                                        :src="util.reImg(item.avatar)"
                                                        alt
                                                        style="width:24px;height:24px;border-radius:50%;float:left;inline-block;line-height:24px;"
                                                    />
                                                    <span style="float:left;display:inline-block;line-height:24px;margin-left:10px;">{{item.name}}（{{item.type==1?'工程极客':'雇主'}}）</span>
                                                </div>
                                                <p style="color:red;margin-top:10px;">{{item.content}}</p>
                                                <div style="margin-top:10px;padding-bottom:50px;">
                                                    <div style="float:left;width:50px;color:#999">附件：</div>
                                                    <div style="float:left;">
                                                        <p
                                                            v-for="(i,idx) in item.files"
                                                            :key="idx"
                                                            v-if="i"
                                                        >
                                                            {{i.name}}&nbsp;
                                                            <a
                                                                href="javascript:;"
                                                                style="color:#587ddc;font-size:12px;"
                                                                @click="downloadFile(i.file)"
                                                            >下载</a>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </el-card>
                                </el-timeline-item>

                                <!--平台调解意见-->
                                <el-timeline-item
                                    v-if="task.appeal_is_deal==1"
                                    placement="top"
                                    :timestamp="task.appeal_deal.content_time"
                                >
                                    <div class="card-bd">
                                        <el-card>
                                            <div
                                                :style="{backgroundImage:'url('+$img.title_bg+')'}"
                                                style="margin-left:-20px;margin-right:20px;font-size:16px;font-weight:normal;color:#555;letter-spacing:2px;width:890px;height:50px;line-height:48px;float:none;clear:both;text-align:center;border-top:2px solid #ddd;border-bottom:2px solid #ddd;"
                                            >平台调解意见</div>

                                            <!--调解意见内容-->
                                            <div class="title">调解意见内容：</div>
                                            <p style="color:rgb(88, 125, 220);margin-top:10px;">{{task.appeal_deal.content}}</p>

                                            <!--调解意见资金分配情况-->
                                            <div class="title">调解意见资金分配情况：</div>
                                            <table class="el-table">
                                                <thead>
                                                    <tr>
                                                        <th
                                                            lay-data="{align:'center'}"
                                                            colspan="6"
                                                            style="font-size:16px;height:40px;"
                                                        >雇主</th>
                                                    </tr>

                                                    <tr>
                                                        <th
                                                            lay-data="{align:'center'}"
                                                            style="font-size:14px;width:16%;"
                                                            class="th-color"
                                                        >姓名</th>
                                                        <th
                                                            lay-data="{align:'center'}"
                                                            style="font-size:14px;width:16%;"
                                                            class="th-color"
                                                        >退还雇主争议酬金</th>
                                                        <th
                                                            lay-data="{align:'center'}"
                                                            style="font-size:14px;width:16%;"
                                                            class="th-color"
                                                        >雇主获得履约保证金</th>
                                                        <th
                                                            lay-data="{align:'center'}"
                                                            style="font-size:14px;width:16%;"
                                                            class="th-color"
                                                        >平台服务费</th>
                                                        <th
                                                            lay-data="{align:'center'}"
                                                            style="font-size:14px;width:16%;"
                                                            class="th-color"
                                                        >雇主实得履约保证金</th>
                                                        <th
                                                            lay-data="{align:'center'}"
                                                            style="font-size:14px;width:20%;"
                                                            class="th-color"
                                                        >合计</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    <tr>
                                                        <td>{{task.employer.true_name}}</td>
                                                        <td>{{task.appeal_deal.employer_remuneration}}</td>
                                                        <td class="tr-color">{{task.appeal_deal.employer_bond}}</td>
                                                        <td class="tr-color">{{task.appeal_deal.employer_bond_server}}</td>
                                                        <td class="tr-color">{{task.appeal_deal.employer_bond_true}}</td>
                                                        <td>{{task.appeal_deal.employer_remuneration+task.appeal_deal.employer_bond_true}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <table class="el-table">
                                                <thead>
                                                    <tr>
                                                        <th
                                                            lay-data="{align:'center'}"
                                                            colspan="6"
                                                            style="font-size:16px;height:40px;"
                                                        >工程极客</th>
                                                    </tr>

                                                    <tr>
                                                        <th
                                                            lay-data="{align:'center'}"
                                                            style="font-size:14px;width:16%;"
                                                            class="th-color"
                                                        >姓名</th>
                                                        <th
                                                            lay-data="{align:'center'}"
                                                            style="font-size:14px;width:16%;"
                                                            class="th-color"
                                                        >退还极客履约保证金</th>
                                                        <th
                                                            lay-data="{align:'center'}"
                                                            style="font-size:14px;width:16%;"
                                                            class="th-color"
                                                        >极客获得争议酬金</th>
                                                        <th
                                                            lay-data="{align:'center'}"
                                                            style="font-size:14px;width:16%;"
                                                            class="th-color"
                                                        >平台服务费</th>
                                                        <th
                                                            lay-data="{align:'center'}"
                                                            style="font-size:14px;width:16%;"
                                                            class="th-color"
                                                        >极客实得争议酬金</th>
                                                        <th
                                                            lay-data="{align:'center'}"
                                                            style="font-size:14px;width:20%;"
                                                            class="th-color"
                                                        >合计</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    <tr>
                                                        <td>{{task.trade.geek.true_name}}</td>
                                                        <td>{{task.appeal_deal.geek_bond}}</td>
                                                        <td class="tr-color">{{task.appeal_deal.geek_remuneration}}</td>
                                                        <td class="tr-color">{{task.appeal_deal.geek_remuneration_server}}</td>
                                                        <td class="tr-color">{{task.appeal_deal.geek_remuneration_true}}</td>
                                                        <td>{{task.appeal_deal.geek_remuneration_true+task.appeal_deal.geek_bond}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <template v-if="userInfo.info.user_type==1">
                                                <p style="margin-top:20px;width:100%;text-align:center;">
                                                    <el-button
                                                        type="primary"
                                                        @click="acceptAppealDeal(1)"
                                                        v-if="task.appeal_deal.geek_status==0"
                                                    >接受</el-button>

                                                    <el-button
                                                        type="primary"
                                                        style="opacity:.9"
                                                        disabled
                                                        v-if="task.appeal_deal.geek_status==1"
                                                    >已接受</el-button>

                                                    <el-button
                                                        type="info"
                                                        @click="acceptAppealDeal(2)"
                                                        v-if="task.appeal_deal.geek_status==0"
                                                    >拒绝</el-button>

                                                    <el-button
                                                        type="info"
                                                        style="opacity:.9"
                                                        disabled
                                                        v-if="task.appeal_deal.geek_status==2"
                                                    >已拒绝</el-button>
                                                </p>
                                            </template>

                                            <template v-if="userInfo.info.user_type==2">
                                                <p style="margin-top:20px;width:100%;text-align:center;">
                                                    <el-button
                                                        type="primary"
                                                        @click="acceptAppealDeal(1)"
                                                        v-if="task.appeal_deal.employer_status==0"
                                                    >接受</el-button>

                                                    <el-button
                                                        type="primary"
                                                        style="opacity:.9"
                                                        disabled
                                                        v-if="task.appeal_deal.employer_status==1"
                                                    >已接受</el-button>

                                                    <el-button
                                                        type="info"
                                                        @click="acceptAppealDeal(2)"
                                                        v-if="task.appeal_deal.employer_status==0"
                                                    >拒绝</el-button>

                                                    <el-button
                                                        type="info"
                                                        style="opacity:.9"
                                                        disabled
                                                        v-if="task.appeal_deal.employer_status==2"
                                                    >已拒绝</el-button>
                                                </p>
                                            </template>
                                        </el-card>
                                    </div>
                                </el-timeline-item>

                                <!--上传仲裁书-->
                                <el-timeline-item
                                    placement="top"
                                    :timestamp="task.appeal_deal.geek_upload_time?task.appeal_deal.geek_upload_time:task.appeal_deal.employer_upload_time"
                                    v-if="task && task.appeal_deal && (task.appeal_deal.geek_status==2 || task.appeal_deal.employer_status==2)"
                                >
                                    <div
                                        class="card-bd"
                                        v-if="task && task.appeal_deal"
                                    >
                                        <el-card style="height:390px;">
                                            <div
                                                class="title"
                                                v-if="task.appeal_deal.geek_file || task.appeal_deal.employer_file"
                                                style="font-size:16px;font-weight:normal;margin-top:0px;"
                                            >仲裁书文件</div>
                                            <div
                                                class="title"
                                                v-else
                                                style="font-size:16px;font-weight:normal;margin-top:0px;"
                                            >上传仲裁书</div>

                                            <div
                                                class="text red"
                                                v-if="task.appeal_deal.geek_status==2 || task.appeal_deal.employer_status==2"
                                            >
                                                <span v-if="task.appeal_deal.employer_status==2">雇主未同意平台调解意见，</span>
                                                <span v-if="task.appeal_deal.geek_status==2">工程极客未同意平台调解意见，</span>
                                                请您尽快向北京仲裁委员会提交仲裁。并在收到仲裁书后，通过此页面上传。如有疑问可咨询询龙网官方客服电话 400-003-6700
                                            </div>

                                            <!--已经上传仲裁书-->
                                            <div
                                                v-if="task.appeal_deal.geek_file || task.appeal_deal.employer_file"
                                                class="upload_zcs"
                                            >
                                                <template v-if="userInfo.info.user_type==1">
                                                    <div
                                                        class="zcs_img_box"
                                                        v-if="task.appeal_deal && task.appeal_deal.geek_file"
                                                    >
                                                        <a
                                                            target="_bank"
                                                            title="点击查看"
                                                            :href="util.reImg(task.appeal_deal.geek_file)"
                                                        >
                                                            <el-image
                                                                style="margin-top:20px;width:100px;height:auto;"
                                                                :src="util.reImg(task.appeal_deal.geek_file)"
                                                                v-if="task.appeal_deal.geek_file"
                                                            ></el-image>
                                                        </a>
                                                        <div style="color:#666;margin-top:10px;">极客仲裁书文件</div>
                                                        <div style="font-size:12px;color:#999;margin-top:10px;">{{task.appeal_deal.geek_upload_time}}</div>
                                                    </div>

                                                    <div
                                                        class="zcs_img_box"
                                                        v-if="task.appeal_deal && task.appeal_deal.employer_file"
                                                    >
                                                        <a
                                                            target="_bank"
                                                            title="点击查看"
                                                            :href="util.reImg(task.appeal_deal.employer_file)"
                                                        >
                                                            <el-image
                                                                style="margin-top:20px;width:100px;height:auto;"
                                                                :src="util.reImg(task.appeal_deal.employer_file)"
                                                                v-if="task.appeal_deal.employer_file"
                                                            ></el-image>
                                                        </a>
                                                        <div style="color:#666;margin-top:10px;">雇主仲裁书文件</div>
                                                        <div style="font-size:12px;color:#999;margin-top:10px;">{{task.appeal_deal.employer_upload_time}}</div>
                                                    </div>

                                                    <div
                                                        class="zcs_img_box"
                                                        v-if="task.appeal_deal && !task.appeal_deal.geek_file && task.appeal_deal.status==2"
                                                    >
                                                        <only-upload
                                                            @onupload="onupload"
                                                            :before-upload="before_upload"
                                                        >
                                                            <el-link
                                                                icon="jk-icon-shangchuan"
                                                                slot="trigger"
                                                                type="primary"
                                                                :underline="false"
                                                            >{{ appeal_file?'重新上传':'点我上传图片' }}</el-link>
                                                        </only-upload>

                                                        <div
                                                            style="width:100%;height:110px;overflow:hidden;margin-bottom:8px;"
                                                            v-if="appeal_file"
                                                        >
                                                            <el-image
                                                                style="margin-top:6px;height:100px;width:auto;"
                                                                :src="util.reImg(appeal_file)"
                                                            ></el-image>
                                                        </div>

                                                        <el-button
                                                            type="primary"
                                                            v-if="appeal_file"
                                                            @click="appeal_file_upload"
                                                        >提交</el-button>
                                                    </div>
                                                </template>

                                                <template v-else>
                                                    <div
                                                        class="zcs_img_box"
                                                        v-if="task.appeal_deal && task.appeal_deal.employer_file"
                                                    >
                                                        <a
                                                            target="_bank"
                                                            title="点击查看"
                                                            :href="util.reImg(task.appeal_deal.employer_file)"
                                                        >
                                                            <el-image
                                                                style="margin-top:20px;width:100px;height:auto;"
                                                                :src="util.reImg(task.appeal_deal.employer_file)"
                                                                v-if="task.appeal_deal.employer_file"
                                                            ></el-image>
                                                        </a>
                                                        <div style="color:#666;margin-top:10px;">雇主仲裁书文件</div>
                                                        <div style="font-size:12px;color:#999;margin-top:10px;">{{task.appeal_deal.geek_upload_time}}</div>
                                                    </div>

                                                    <div
                                                        class="zcs_img_box"
                                                        v-if="task.appeal_deal && task.appeal_deal.geek_file"
                                                    >
                                                        <a
                                                            target="_bank"
                                                            title="点击查看"
                                                            :href="util.reImg(task.appeal_deal.geek_file)"
                                                        >
                                                            <el-image
                                                                style="margin-top:20px;width:100px;height:auto;"
                                                                :src="util.reImg(task.appeal_deal.geek_file)"
                                                                v-if="task.appeal_deal.geek_file"
                                                            ></el-image>
                                                        </a>
                                                        <div style="color:#666;margin-top:10px;">极客仲裁书文件</div>
                                                        <div style="font-size:12px;color:#999;margin-top:10px;">{{task.appeal_deal.geek_upload_time}}</div>
                                                    </div>

                                                    <div
                                                        class="zcs_img_box"
                                                        v-if="task.appeal_deal && !task.appeal_deal.employer_file && task.appeal_deal.status==2"
                                                    >
                                                        <only-upload
                                                            @onupload="onupload"
                                                            :before-upload="before_upload"
                                                        >
                                                            <el-link
                                                                icon="jk-icon-shangchuan"
                                                                slot="trigger"
                                                                type="primary"
                                                                :underline="false"
                                                            >{{ appeal_file?'重新上传':'点我上传图片' }}</el-link>
                                                        </only-upload>

                                                        <div
                                                            style="width:100%;height:120px;overflow:hidden;margin-bottom:8px;"
                                                            v-if="appeal_file"
                                                        >
                                                            <el-image
                                                                style="margin-top:6px;height:110px;width:auto;"
                                                                :src="util.reImg(appeal_file)"
                                                            ></el-image>
                                                        </div>

                                                        <el-button
                                                            type="primary"
                                                            v-if="appeal_file"
                                                            @click="appeal_file_upload"
                                                        >提交</el-button>
                                                    </div>
                                                </template>
                                            </div>

                                            <!--未上传仲裁书-->
                                            <div
                                                class="zcs_upload"
                                                v-else
                                            >
                                                <only-upload
                                                    @onupload="onupload"
                                                    :before-upload="before_upload"
                                                >
                                                    <el-link
                                                        icon="jk-icon-shangchuan"
                                                        slot="trigger"
                                                        type="primary"
                                                        :underline="false"
                                                    >{{ appeal_file?'重新上传':'点我上传图片' }}</el-link>
                                                </only-upload>

                                                <div
                                                    slot="tip"
                                                    class="el-upload__tip"
                                                    v-if="!appeal_file"
                                                >请上传小于5M的png、jpg、jpeg、gif等格式图片</div>

                                                <div
                                                    style="width:100%;height:120px;overflow:hidden;"
                                                    v-if="appeal_file"
                                                >
                                                    <el-image
                                                        style="margin-top:20px;width:100px;height:auto;"
                                                        :src="util.reImg(appeal_file)"
                                                    ></el-image>
                                                </div>

                                                <el-button
                                                    type="primary"
                                                    v-if="appeal_file"
                                                    @click="appeal_file_upload"
                                                >提交</el-button>
                                            </div>
                                        </el-card>
                                    </div>
                                </el-timeline-item>

                                <!--资金分配详情-->
                                <el-timeline-item
                                    v-if="task.appeal_deal && task.appeal_deal.deal_appeal_time"
                                    placement="top"
                                    :timestamp="task.appeal_deal.deal_appeal_time"
                                >
                                    <div class="card-bd">
                                        <el-card>
                                            <div style="font-size:16px;font-weight:normal;width:100%;height:30px;float:none;clear:both;">资金分配情况</div>
                                            <table class="el-table">
                                                <thead>
                                                    <tr>
                                                        <th
                                                            lay-data="{align:'center'}"
                                                            colspan="6"
                                                            style="font-size:16px;height:40px;"
                                                        >雇主</th>
                                                    </tr>

                                                    <tr>
                                                        <th
                                                            lay-data="{align:'center'}"
                                                            style="font-size:14px;width:16%;"
                                                            class="th-color"
                                                        >姓名</th>
                                                        <th
                                                            lay-data="{align:'center'}"
                                                            style="font-size:14px;width:16%;"
                                                            class="th-color"
                                                        >退还雇主争议酬金</th>
                                                        <th
                                                            lay-data="{align:'center'}"
                                                            style="font-size:14px;width:16%;"
                                                            class="th-color"
                                                        >雇主获得履约保证金</th>
                                                        <th
                                                            lay-data="{align:'center'}"
                                                            style="font-size:14px;width:16%;"
                                                            class="th-color"
                                                        >平台服务费</th>
                                                        <th
                                                            lay-data="{align:'center'}"
                                                            style="font-size:14px;width:16%;"
                                                            class="th-color"
                                                        >雇主实得履约保证金</th>
                                                        <th
                                                            lay-data="{align:'center'}"
                                                            style="font-size:14px;width:20%;"
                                                            class="th-color"
                                                        >合计</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    <tr>
                                                        <td>{{task.employer.true_name}}</td>
                                                        <td>{{task.appeal_deal.employer_get_remuneration}}</td>
                                                        <td class="tr-color">{{task.appeal_deal.employer_get_bond}}</td>
                                                        <td class="tr-color">{{task.appeal_deal.employer_get_bond_server}}</td>
                                                        <td class="tr-color">{{task.appeal_deal.employer_get_bond_true}}</td>
                                                        <td>{{task.appeal_deal.employer_get_remuneration+task.appeal_deal.employer_get_bond_true}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <table class="el-table">
                                                <thead>
                                                    <tr>
                                                        <th
                                                            lay-data="{align:'center'}"
                                                            colspan="5"
                                                            style="font-size:16px;height:40px;"
                                                        >工程极客</th>
                                                    </tr>

                                                    <tr>
                                                        <th
                                                            lay-data="{align:'center'}"
                                                            style="font-size:14px;width:16%;"
                                                            class="th-color"
                                                        >姓名</th>
                                                        <th
                                                            lay-data="{align:'center'}"
                                                            style="font-size:14px;width:16%;"
                                                            class="th-color"
                                                        >退还极客履约保证金</th>
                                                        <th
                                                            lay-data="{align:'center'}"
                                                            style="font-size:14px;width:16%;"
                                                            class="th-color"
                                                        >极客获得争议酬金</th>
                                                        <th
                                                            lay-data="{align:'center'}"
                                                            style="font-size:14px;width:16%;"
                                                            class="th-color"
                                                        >平台服务费</th>
                                                        <th
                                                            lay-data="{align:'center'}"
                                                            style="font-size:14px;width:16%;"
                                                            class="th-color"
                                                        >极客实得争议酬金</th>
                                                        <th
                                                            lay-data="{align:'center'}"
                                                            style="font-size:14px;width:20%;"
                                                            class="th-color"
                                                        >合计</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    <tr>
                                                        <td>{{task.trade.geek.true_name}}</td>
                                                        <td>{{task.appeal_deal.geek_get_bond}}</td>
                                                        <td class="tr-color">{{task.appeal_deal.geek_get_remuneration}}</td>
                                                        <td class="tr-color">{{task.appeal_deal.geek_get_remuneration_server}}</td>
                                                        <td class="tr-color">{{task.appeal_deal.geek_get_remuneration_true}}</td>
                                                        <td>{{task.appeal_deal.geek_get_remuneration_true+task.appeal_deal.geek_get_bond}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <p style="margin-top:20px;width:100%;text-align:center;">
                                                <el-button style="background:rgb(160, 207, 255);color:#fff;border:none;cursor:not-allowed">调解结束，项目结束</el-button>
                                            </p>
                                        </el-card>
                                    </div>
                                </el-timeline-item>

                                <el-timeline-item v-if="task.appeal_deal && task.appeal_deal.status==0">
                                    <div>
                                        <el-button
                                            type="primary"
                                            @click="appeal"
                                        >添加调解内容</el-button>
                                    </div>
                                </el-timeline-item>
                            </el-timeline>
                        </div>
                    </div>
                </div>
            </div>

            <div style="height:80px;width:100%;text-align:center;">
                <el-button @click="goBack">返回</el-button>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import onlyUpload from "@/components/upload/only-upload.vue";
import UploadAnnex from "@/components/upload/UploadAnnex.vue";
export default {
    name: "taskResults",
    metaInfo() {
        return {
            title: "调解详情 - 询龙网",
            meta: [
                {
                    name: "keywords",
                    content: ""
                }, {
                    name: "description",
                    content: ''
                }
            ]
        }
    },
    components: {
        UploadAnnex, //上传附件组件
        onlyUpload
    },
    data() {
        return {
            task: "", //项目详情
            trade: "", //邀请信息
            is_subscribe: 0, //是否关注
            appeal_file: ""
        };
    },
    computed: {
        ...mapState(["userInfo"])
    },
    methods: {
        //获取项目信息
        loadData: function () {
            let _this = this;
            _this.post(
                "/task/task/detail_todo",
                { tk: _this.$route.query.tk },
                res => {
                    if (res.code == 200) {
                        _this.task = res.data;
                        _this.trade = res.data.trade;
                    } else {
                        if (res.msg) {
                            _this.$message.success(res.msg);
                        } else {
                            _this.$message.success("获取失败");
                        }
                    }
                }
            );
        },

        //发送消息
        sendMessage(val) {
            //侧边栏是否保持展开
            if (val && val.isShowDraw == "isShowDraw") {
                sessionStorage.setItem("isShowDraw", true);
            }
            sessionStorage.setItem(
                "currentPageUrl",
                document.location.pathname + document.location.search
            ); //存储当前值
            this.$router.push("/user/message/sendmsg?uk=" + val);
        },

        //是否关注作者
        subscribe() {
            let _this = this;
            let uk = "";
            if (_this.trade && _this.trade.geek.uk) {
                uk = _this.trade.geek.uk;
            }
            this.post(
                "/user/index/is_subscribe",
                {
                    uk: uk
                },
                function (res) {
                    if (res.code == 200) {
                        if (res.data.is_subscribe == 1) {
                            _this.is_subscribe = 1;
                        } else {
                            _this.is_subscribe = 0;
                        }
                    } else {
                        _this.$message.error(res.msg);
                    }
                }
            );
        },

        //关注用户
        keep(uk) {
            let _this = this;
            this.post(
                "/user/user/collect_user",
                {
                    uk: uk
                },
                function (res) {
                    if (res.code == 200) {
                        if (res.data == 1) {
                            //关注成功
                            _this.is_subscribe = 1;
                            _this.$message({
                                message: res.msg,
                                type: "success"
                            });
                        } else {
                            //取消关注
                            _this.is_subscribe = 0;
                            _this.$message({
                                message: res.msg,
                                type: "success"
                            });
                        }
                    } else {
                        _this.$message.error(res.msg);
                    }
                }
            );
        },

        //上传仲裁书
        onupload(file) {
            this.appeal_file = file.file;
        },

        //提交仲裁书上传文件到服务器
        appeal_file_upload() {
            let _this = this;
            const h = this.$createElement;
            _this.$msgbox({
                title: '提示',
                message: h('p', null, [
                    h('span', null, '您即将提交仲裁书，请注意，仲裁书一旦提交则无法改动。 ')
                ]),
                showCancelButton: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                beforeClose: (action, instance, done) => {
                    if (action === 'confirm') {
                        instance.confirmButtonLoading = true;
                        instance.confirmButtonText = '上传中...';
                        setTimeout(() => {
                            done();
                            _this.post(
                                "/task/task/upload_appeal_file",
                                {
                                    tk: _this.$route.query.tk,
                                    appeal_file: _this.appeal_file
                                },
                                function (res) {
                                    if (res.code == 200) {
                                        _this.$message.success("上传成功");
                                    } else {
                                        if (res.msg) {
                                            _this.$message.error(res.msg);
                                        } else {
                                            _this.$message.error("上传失败");
                                        }
                                    }
                                    _this.loadData();
                                }
                            );
                            setTimeout(() => {
                                instance.confirmButtonLoading = false;
                            }, 300);
                        }, 3000);
                    } else {
                        done();
                    }
                }
            }).then(action => {

            });
        },

        //查看或下载附件
        view(url) {
            window.open(this.websiteConfigs.sourceUrl + url, "_blank");
        },

        //添加调解内容
        appeal() {
            this.$router.push("/user/task/appeal?tk=" + this.$route.query.tk);
        },

        downloadFile(path) {
            window.open(this.websiteConfigs.sourceUrl + path, "_blank");
        },

        //身份证图片上传之前
        before_upload() {
            if (file.name) {
                var index = file.name.lastIndexOf(".");
                var suffix = file.name.substr(index + 1);
                if ("jpg" == suffix || "jpeg" == suffix || "png" == suffix || "bmp" == suffix
                ) { } else {
                    this.$message.error(
                        "上传文件格式不正确，请上传jpg，jpeg，png，bmp等格式的文件"
                    );
                    return false;
                }
            }
        },

        //处理调解意见
        acceptAppealDeal(val) {
            let _this = this;
            _this.post(
                "/task/task/accept_appeal_deal",
                { tk: _this.$route.query.tk, status: val },
                function (res) {
                    if (res.code == 200) {
                        _this.$message.success("提交成功");
                    } else {
                        if (res.msg) {
                            _this.$message.error(res.msg);
                        } else {
                            _this.$message.error("提交失败");
                        }
                    }
                    _this.loadData();
                }
            );
        },

        //返回
        goBack() {
            this.$router.go(-1);
        }
    },
    created() {
        this.loadData(); //获取项目详情
        setTimeout(() => {
            this.subscribe(); //查询是否已关注
        }, 1000);
    }
};
</script>

<style lang="less" scroped>
@import "../../../styles/taskData.less";
.el-timeline {
    .tit {
        margin-top: 0;
    }
}
.filist-list {
    font-size: 0;
    margin: 0 -10px;

    .file-item {
        display: inline-block;
        width: 80px;
        height: 80px;
        margin: 0 5px;
        border: 1px solid #f0f0f0;
        background-size: 47px 60px !important;
        background-repeat: no-repeat !important;
        background-position: center center !important;

        &:hover {
            border-color: #587ddc;
        }
    }

    .icon-ppt {
        background: url("~@/assets/img/icon_doc.png") no-repeat center center;
    }

    .icon-doc {
        background: url("~@/assets/img/icon_jpg.png") no-repeat center center;
    }

    .icon-xls {
        background: url("~@/assets/img/icon_pdf.png") no-repeat center center;
    }

    .icon-jpg {
        background: url("~@/assets/img/icon_png.png") no-repeat center center;
    }

    .icon-png {
        background: url("~@/assets/img/icon_ppt.png") no-repeat center center;
    }

    .icon-pdf {
        background: url("~@/assets/img/icon_rar.png") no-repeat center center;
    }

    .icon-zip {
        background: url("~@/assets/img/icon_xls.png") no-repeat center center;
    }

    .icon-rar {
        background: url("~@/assets/img/icon_zip.png") no-repeat center center;
    }
}
.jk-timeline {
    .el-timeline-item {
        padding: 20px 0;
        .tit {
            color: #666;
        }
        &.active {
            background: white;
            .el-card__body {
                background: white;
            }
            .tit {
                color: #333;
            }
        }
    }
    .el-card__body {
        background: #f0f0f0;
    }
}

.br-bd {
    .title {
        font-size: 14px;
        font-weight: normal;
        color: #666;
        margin-top: 30px;
    }
    table {
        margin-top: 20px;
        border-collapse: collapse;
        th,
        td {
            text-align: center;
            border: 1px solid #ddd;
        }
    }
}

.card-bd {
    .text {
        color: #666;
        margin-top: 20px;
        width: 100%;
        text-align: center;
    }

    .green {
        color: green;
    }

    .red {
        color: red;
    }
}

.zcs_upload {
    width: 100%;
    min-height: 150px;
    border-radius: 6px;
    text-align: center;
    padding-bottom: 30px;
    button {
        margin-top: 10px;
    }
    .upload-el {
        margin-top: 20px;
    }
}

.el-link--inner {
    margin-left: 6px;
    font-size: 16px;
}

.upload_zcs {
    width: 100%;
    height: 250px;
    overflow: hidden;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    .zcs_img_box {
        border: 1px solid #eee;
        padding: 10px 10px 10px 10px;
        border-radius: 6px;
        width: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-right: 20px;
    }
}
.th-color {
    background-color: #1756f1c2 !important;
    color: #ffffff;
}
.tr-color {
    background-color: #868d9e52 !important;
}

.el-table {
    font-size: 14px;
    font-weight: normal;

    thead {
        tr {
            th {
                font-size: 14px;
                font-weight: normal;
            }
        }
    }
}

.zyzj_table {
    color: #666;
    font-size: 14px;
    font-weight: normal;

    thead {
        tr {
            th {
                color: #999;
                font-size: 14px;
                font-weight: normal;
            }
        }
    }
}
</style>