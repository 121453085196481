<template>
    <el-upload
        class="upload-el"
        ref="upload"
        :action="action"
        :headers="imgHeaders"
        :multiple="false"
        :on-progress="on_progress"
        :on-success="on_success"
        :on-error="on_error"
        :before-upload="before_upload"
        v-loading="isLoading"
        :show-file-list="false"
    >
        <div slot="trigger">
            <slot name="trigger"></slot>
        </div>
        <slot></slot>
    </el-upload>
</template>
<script>
export default {
    data() {
        return {
            isLoading: false,
            action: ""
        };
    },
    computed: {
        imgHeaders: function () {
            //图片上传头部信息
            return {
                token: sessionStorage.getItem("token"),
                timestamp: new Date().getTime(),
                secret: this.websiteConfigs.secret
            };
        }
    },
    created() {
        var token = sessionStorage.getItem("token");
        this.action =
            this.websiteConfigs.baseUrl +
            this.websiteConfigs.uploadUrl +
            "?group=user";
    },
    methods: {
        on_progress(event, file, fileList) { },

        on_success(response, file, fileList) {
            this.isLoading = false;
            this.$emit("onupload", response.data);
        },

        on_error(err, file, fileList) {
            this.isLoading = false;
        },

        //上传前
        before_upload(file) {
            if (file.name) {
                var index = file.name.lastIndexOf(".");
                var suffix = file.name.substr(index + 1);
                if ("jpg" == suffix || "jpeg" == suffix || "png" == suffix || "gif" == suffix ||
                    "zip" == suffix || "rar" == suffix || "7z" == suffix ||
                    "pdf" == suffix || "doc" == suffix || "docx" == suffix || "ppt" == suffix || "pptx" == suffix || "xls" == suffix || "xlsx" == suffix
                ) { } else {
                    this.$message.error(
                        "上传文件格式不正确，请上传jpg，jpeg，png，gif，zip，rar，7z，doc，docx，ppt，pptx，xls，xlsx，pdf等格式的文件"
                    );
                    return false;
                }
            }
            this.isLoading = true;
            this.imgHeaders.timestamp = new Date().getTime();
        }
    }
};
</script>