<template>
    <div class="_upload">
        <div class="boxer">
            <div
                class="item"
                v-if="add"
                style="width:80px;"
            >
                <el-upload
                    :action="imgUploadUrl"
                    :headers="imgHeaders"
                    :auto-upload="true"
                    :disabled="disabled || false"
                    :multiple="false"
                    :before-upload="uploadBeforeEvent"
                    :on-success="uploadSuccessEvent"
                    :on-progress="uploadProgressEvent"
                    :show-file-list="false"
                >
                    <span v-if="isUpload">
                        <img
                            class="add"
                            :src="$img.add_annex"
                        />
                        <span class="add-text">添加附件</span>
                    </span>
                    <span v-else>
                        <el-progress
                            type="circle"
                            :width="60"
                            :percentage="uploadPress"
                        ></el-progress>
                    </span>
                </el-upload>
            </div>
            <div
                class="item annex"
                v-for="(item,index) in value"
                :key="index"
                style="width:80px;"
            >
                <el-tooltip
                    placement="top"
                    effect="light"
                >
                    <div slot="content">
                        <span style="font-weight:bold">{{item.name}}</span>
                        <br />
                        <span>大小：{{item.size}}</span>
                        <br />
                        <span>上传时间：{{item.date}}</span>
                        <br />
                    </div>
                    <img
                        :src="item.icon"
                        @click="view(item.url)"
                    />
                </el-tooltip>
                <div
                    class="del"
                    @click="del(item.hash,item.date)"
                ></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "UploadAnnex",
    props: ['value', 'disabled'],
    data() {
        return {
            add: true,//是否显示添加按钮
            annexList: [],//附件列表
            imgUploadUrl: "",//附件上传地址
            imgHeaders: {//上传附件需要的头部信息
                token: "",
                timestamp: "",
                secret: ""
            },
            uploadPress: 0,//附件上传进度条
            isUpload: true,//不显示进度条
        }
    },
    methods: {
        //附件上传前事件
        uploadBeforeEvent(file, x) {
            if (file.name) {
                var index = file.name.lastIndexOf(".");
                var suffix = file.name.substr(index + 1);
                if ("jpg" == suffix || "jpeg" == suffix || "png" == suffix || "gif" == suffix ||
                    "zip" == suffix || "rar" == suffix || "7z" == suffix ||
                    "pdf" == suffix || "doc" == suffix || "docx" == suffix || "ppt" == suffix || "pptx" == suffix || "xls" == suffix || "xlsx" == suffix
                ) { } else {
                    this.$message.error("上传文件格式不正确，请上传jpg，jpeg，png，gif，zip，rar，7z，doc，docx，ppt，pptx，xls，xlsx，pdf等格式的文件");
                    return false;
                }
            }

            this.isUpload = false;//显示进度条
            this.uploadPress = 0;//重置进度条
            this.imgHeaders.timestamp = new Date().getTime();
            if (this.annexList && this.annexList.length >= 8) {
                this.$message.error('最多只能上传8个附件');
                return false;
            }
        },

        //附件上传进度条事件
        uploadProgressEvent(event, file, fileList) {
            this.uploadPress = parseInt(event.percent);
        },

        //附件上传成功事件
        uploadSuccessEvent(file, x) {
            if (file.code == -300) {
                this.$message.error("文件太大或不支持的文件格式");
                this.isUpload = true;
                return false;
            }

            let icon = file.data.file.replace(/.+\./, "").toLowerCase();//获取文件后缀名
            switch (icon) {
                case 'doc':
                    icon = this.$img.icon_doc;
                    break;
                case 'docx':
                    icon = this.$img.icon_doc;
                    break;
                case 'xls':
                    icon = this.$img.icon_xls;
                    break;
                case 'xlsx':
                    icon = this.$img.icon_xls;
                    break;
                case 'ppt':
                    icon = this.$img.icon_ppt;
                    break;
                case 'pptx':
                    icon = this.$img.icon_ppt;
                    break;
                case 'zip':
                    icon = this.$img.icon_zip;
                    break;
                case 'rar':
                    icon = this.$img.icon_rar;
                    break;
                case 'jpg':
                    icon = this.$img.icon_jpg;
                    break;
                case 'jpeg':
                    icon = this.$img.icon_jpg;
                    break;
                case 'png':
                    icon = this.$img.icon_png;
                    break;
                case 'pdf':
                    icon = this.$img.icon_pdf;
                    break;
                default:
                    this.$message.error('上传文件格式不支持');
                    this.isUpload = true;
                    return;
                    break;
            }

            if (!this.annexList) {
                this.annexList = [];
            }

            //上传成功后加入到附件列表
            this.annexList.push({
                name: file.data.name,
                icon: icon,
                url: file.data.file,
                size: this.converSize(file.data.size),
                date: file.data.ctime,
                hash: file.data.hash
            });

            if (this.annexList && this.annexList.length >= 8) {
                this.add = false;//不显示添加附件按钮
            }
            this.isUpload = true;//不显示进度条
            this.$emit('input', this.annexList);
            this.$emit('success', true);
        },

        converSize(limit) {
            limit = parseFloat(limit);
            var size = "";
            if (limit < 0.1 * 1024) {
                //如果小于0.1KB转化成B
                size = limit.toFixed(2) + "KB";
            } else if (limit < 0.1 * 1024 * 1024) {
                //如果小于0.1MB转化成KB
                size = (limit / 1024).toFixed(2) + "MB";
            } else if (limit < 0.1 * 1024 * 1024 * 1024) {
                //如果小于0.1GB转化成MB
                size = (limit / (1024 * 1024)).toFixed(2) + "GB";
            } else {
                //其他转化成GB
                size = (limit / (1024 * 1024 * 1024)).toFixed(2) + "TB";
            }

            var sizestr = size + "";
            var len = sizestr.indexOf(".");
            var dec = sizestr.substr(len + 1, 2);
            if (dec == "00") {
                //当小数点后为00时 去掉小数部分
                return sizestr.substring(0, len) + sizestr.substr(len + 3, 2);
            }
            return sizestr;
        },

        //获取上传头部信息
        getUploadHeaderInfo() {
            this.imgUploadUrl = this.websiteConfigs.baseUrl + this.websiteConfigs.uploadUrl + "?group=task&thumb_type=task"; //获取上传地址
            this.imgHeaders.token = sessionStorage.getItem("token");
            this.imgHeaders.timestamp = new Date().getTime();
            this.imgHeaders.secret = this.websiteConfigs.secret;
        },

        //删除附件
        del(hash, date) {
            this.$confirm("删除后将不可恢复, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消"
            }).then(() => {
                if (this.annexList && this.annexList.length < 9) {
                    this.add = true;//显示添加附件按钮
                    this.isUpload = true;
                }
                this.annexList = this.removeArray(this.annexList, hash, date);
                this.$emit('input', this.annexList);
                this.$emit('del', true);
            }).catch(() => { });
        },

        //删除数组
        removeArray(_arr, _hash, _date) {
            let length = _arr.length;
            for (let i = 0; i < length; i++) {
                if (_arr[i]["hash"] === _hash && _arr[i]["date"] === _date) {
                    if (i === 0) {
                        _arr.shift(); //删除并返回数组的第一个元素
                        return _arr;
                    } else if (i === length - 1) {
                        _arr.pop(); //删除并返回数组的最后一个元素
                        return _arr;
                    } else {
                        _arr.splice(i, 1); //删除下标为i的元素
                        return _arr;
                    }
                }
            }
        },

        //查看或下载附件
        view(url) {
            window.open(this.websiteConfigs.sourceUrl + url, "_blank");
        }
    },
    created() {
        if (!this.value || this.value == undefined || this.value == '') {
            this.annexList = [];
        } else {
            this.annexList = this.value;
        }
        this.getUploadHeaderInfo();//获取头部信息
        if (this.value && this.value.length >= 8) {
            this.add = false;//不显示添加附件按钮
        }
    },
};
</script>

<style lang="less">
._upload {
    min-height: 100px;
    border: 1px solid #eae9e6;
    padding: 10px;
    overflow: hidden;
    .boxer {
        width: 110%;
        .item {
            position: relative;
            width: 80px;
            height: 80px;
            border: 1px solid #f0f0f0;
            float: left;
            padding-top: 9px;
            text-align: center;
            margin-right: 14px;
            // margin-bottom: 14px;//多行可以把这个打开
            cursor: pointer;
            img {
                height: 60px;
                width: auto;
            }
            .add {
                width: 40px;
                height: auto;
            }
            .add-text {
                color: #828282;
                font-size: 12px;
                display: inline-block;
            }
            .del {
                width: 14px;
                height: 14px;
                display: inline-block;
                background: url("~@/assets/img/icon_del.png") no-repeat center
                    center;
                position: absolute;
                right: -7px;
                top: -7px;
            }
            &:hover {
                border: 1px solid #587ddc;
            }
        }
    }
}
</style>



